import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to, from, next) => {
        if (localStorage.getItem('access_token') == null) {
            next({ path: '/login' });
        } else {
            next();
        }
    },
}, {
    path: '/import',
    name: 'Import',
    component: () => import( /* webpackChunkName: "import" */ '../views/Import.vue'),
    beforeEnter: (to, from, next) => {
        if (localStorage.getItem('access_token') == null) {
            next({ path: '/login' });
        } else {
            next();
        }
    },
}, {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue'),
}]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router