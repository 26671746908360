import Vue from 'vue'
import {message} from 'ant-design-vue'
import App from './App.vue'
import router from './services/router'
import http from './services/http'
import './services/antdv'

Vue.config.productionTip = false

Vue.prototype.$http = http;
Vue.prototype.$message = message;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

