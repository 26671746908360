import Vue from 'vue'

import { 
    Button,
    Col,
    DatePicker,
    Dropdown,
    Form,
    FormModel,
    Icon,
    Input,
    InputNumber,
    Menu,
    Modal,
    PageHeader,
    Pagination,
    Radio,
    Row,
    Select,
    Table,
    Tag,
    Upload,
} from 'ant-design-vue';

Vue.use(Button)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(Dropdown)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Icon)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Modal)
Vue.use(Menu)
Vue.use(PageHeader)
Vue.use(Pagination)
Vue.use(Radio)
Vue.use(Row)
Vue.use(Select)
Vue.use(Table)
Vue.use(Tag)
Vue.use(Upload)


// Ant-Design CSS
import 'ant-design-vue/lib/button/style/css';
import 'ant-design-vue/lib/col/style/css';
import 'ant-design-vue/lib/dropdown/style/css';
import 'ant-design-vue/lib/date-picker/style/css';
import 'ant-design-vue/lib/form/style/css';
import 'ant-design-vue/lib/form-model/style/css';
import 'ant-design-vue/lib/icon/style/css';
import 'ant-design-vue/lib/input/style/css';
import 'ant-design-vue/lib/input-number/style/css';
import 'ant-design-vue/lib/menu/style/css';
import 'ant-design-vue/lib/modal/style/css';
import 'ant-design-vue/lib/message/style/css';
import 'ant-design-vue/lib/page-header/style/css';
import 'ant-design-vue/lib/pagination/style/css';
import 'ant-design-vue/lib/radio/style/css';
import 'ant-design-vue/lib/row/style/css';
import 'ant-design-vue/lib/select/style/css';
import 'ant-design-vue/lib/table/style/css';
import 'ant-design-vue/lib/tag/style/css';
import 'ant-design-vue/lib/upload/style/css';