import axios from 'axios'
import router from './router'

const http = axios.create()

http.defaults.baseURL = process.env.VUE_APP_HTTP_API
http.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.getItem('access_token')
http.defaults.headers.post['Content-Type'] = 'application/json'

http.interceptors.response.use( 
    function (response) {
        return response
    },
    async function (error) {
        const originalRequest = error.config;

        if ( originalRequest.url != '/users/refresh' && error.response.status === 401 && !originalRequest._retry ) {
            originalRequest._retry = true;

            try {
                http.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.getItem('refresh_token')
                await http.post('/users/refresh').then( response => {
                    localStorage.setItem('access_token', response.data.access_token);

                    http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                    originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                } );

                return http(originalRequest);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }

        if ( error.response.status === 401 ) {
            localStorage.removeItem('access_token');
            router.push('/login');
        }
        
        return Promise.reject(error);
    }
);

export default http